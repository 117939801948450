import { Link } from 'gatsby'
import React, { useContext } from 'react';
import TextLoop from 'react-text-loop'
import styled, { ThemeContext } from 'styled-components';
import Container from '../Container'
import { Button } from '../Button';
import getColor, { Color } from '../utils/getColor'
import myoblogo from '../../assets/customerStories/myob.svg'
import employsurelogo from '../../assets/customerStories/employsure.svg'
import ampollogo from '../../assets/customerStories/ampol.svg'
import petsurelogo from '../../assets/customerStories/petsure.png'
import teachersmutualbanklogo from '../../assets/customerStories/teachersmutualbank.png'
import myobstats from '../../assets/customerStories/myob-stats.png'
import employsurestats from '../../assets/customerStories/employsure-stats.png'
import ampolstats from '../../assets/customerStories/ampol-stats.png'
import petsurestats from '../../assets/customerStories/petsure-stats.png'
import teachersmutualbankstats from '../../assets/customerStories/teachersmutualbank-stats.png'


export default () => {
  const themeContext = useContext(ThemeContext);

  return (
    <section id='stories'>
      <Container>
        <Header>
          Take a look at how we deliver <StyledTextLoop mask children={['value', 'meaning', 'insights']} /> to
          businesses who use daisee
        </Header>
      </Container>
      <CustomerStoriesWrapper>
        <Container>
          <Inner>
            <Story>
              <FeatureImg>
                <Img src={myobstats} alt='myob stats' />
              </FeatureImg>
              <Article >
                <ImgWrapper >
                  <Link to={`/customers/stories/myob`}>
                    <Img src={myoblogo} alt='myob logo' />
                  </Link>
                </ImgWrapper>
                <Copy bgColor={Color.Pink}>
                  After implementing daisee, MYOB delivered better customer satisfaction and increased CSAT score by 22%
                </Copy>
                <Copy bgColor={Color.Pink}>
                  An A/B trial showed an increase in sales conversion of 17% in those agents empowered by daisee Insights
                </Copy>
                <Copy bgColor={Color.Pink}>
                  <i>“We’re invested and couldn’t imagine a life without daisee. They have given us real confidence in elevating our quality framework to deliver consistent customer experience in 100% of interactions.”</i> 
                  <br /><br />
                  Steve Targus, <br />
                  MYOB Contact centre manager
                </Copy>
                <Link to={`/customers/stories/myob`}>
                  <Button bgColor={themeContext.colors.green} action="READ more about daisee’s work with myob" btnHeight="auto" />
                </Link>
              </Article>
            </Story>

            <Story>
              <Article >
                <ImgWrapper >
                  <Link to={`/customers/stories/employsure`}>
                    <Img src={employsurelogo} alt='employsure logo' />
                  </Link>
                </ImgWrapper>
                <Copy bgColor={Color.Yellow}>
                  With Daisee, 100% of Employsure’s inbound calls are monitored through automated quality management.
                </Copy>
                <Copy bgColor={Color.Yellow}>
                  Customer dissatisfaction is now identified early, resulting in early resolution and improved customer retention for Employsure
                </Copy>
                <Copy bgColor={Color.Yellow}>
                  <i>“We have placed a lot of trust in daisee, not only in the technology, but in the team as a whole. They’ve not let us down. I’ve been impressed with their professionalism and investment in helping our business succeed. We look forward to working with them on the next stage.”</i>
                  <br /><br />
                  David Oxley, <br />
                  Head of Solutions, Employsure Pty Ltd
                </Copy>
                <Link to={`/customers/stories/employsure`}>
                  <Button bgColor={themeContext.colors.green} action="Read more about daisee’s work with employsure" btnHeight="auto" />
                </Link>
              </Article>
              <FeatureImg>
                <Img src={employsurestats} alt='employsure stats' />
              </FeatureImg>
            </Story>

            <Story>
              <FeatureImg>
                <Img src={ampolstats} alt='ampol stats' />
              </FeatureImg>
              <Article >
                <ImgWrapper >
                  <Link to={`/customers/stories/ampol`}>
                    <Img src={ampollogo} alt='ampol logo' />
                  </Link>
                </ImgWrapper>
                <Copy bgColor={Color.Blue}>
                  In the three months following the implementation of daisee, Ampol’s number of high-risk calls fell by 98%.
                </Copy>
                <Copy bgColor={Color.Blue}>
                  Over 13 months, Agent Compliance and Quality rose from 79.2% to 86.9% – 9.7% growth
                </Copy>
                <Copy bgColor={Color.Blue}>
                  With daisee, Team Leads at Ampol can now see who in their team needs assistance, knowledge, behavioural or soft skills coaching. Using daisee, they can monitor with ease, without having to run reports on how their team members are tracking every day.
                </Copy>
                <Link to={`/customers/stories/ampol`}>
                  <Button bgColor={themeContext.colors.green} action="READ more about daisee’s work with AMPOL" btnHeight="auto" />
                </Link>
              </Article>
            </Story>

            <Story>
              <Article >
                <ImgWrapper >
                  <Img src={petsurelogo} alt='petsure logo' />
                </ImgWrapper>
                <Copy bgColor="#8C52FF">
                  <i>“Sampling calls did not give a high degree of assurance around compliance. With manual processes, we were lucky to sample 1-2% of all calls. With daisee assessing every call, we can put our hand on our heart and say, this is actually our compliance.”</i>
                  <br></br>
                  <br></br>
                  Pete Iannetta
                  <br></br>
                  Head of Customer Service, Petsure
                </Copy>
                <Copy bgColor="#8C52FF">
                  <i>“Being able to quickly identify and notify a team leader that one of their agents may require a check-in or support is a top priority for us, as we place great emphasis on taking care of others”</i>
                  <br></br>
                  <br></br>
                  Brodie Booth,
                  <br></br>
                  Senior Quality Assurance Analyst, Petsure
                </Copy>
                <Copy bgColor="#8C52FF">
                  <i>“daisee has given better insights into why calls are happening, and has enabled us to identify process improvement opportunities”</i>
                  <br></br>
                  <br></br>
                  Pete Iannetta
                  <br></br>
                  Head of Customer Service, Petsure
                </Copy>
              </Article>
              <FeatureImg>
                <Img src={petsurestats} alt='petsure stats' />
              </FeatureImg>
            </Story>

            <Story>
              <FeatureImg>
                <Img src={teachersmutualbankstats} alt='teachers mutual bank stats' />
              </FeatureImg>
              <Article >
                <LargerImgWrapper >
                  <Img src={teachersmutualbanklogo} alt='teachers mutual bank logo' />
                </LargerImgWrapper>
                <Copy bgColor={Color.Pink}>
                  <i>“daisee gives us great insights into skills staff members need coaching on, even sentiment and empathy metrics.”</i>
                  <br></br>
                  <br></br>
                  Bette Safour,
                  <br></br>
                  National Contact Centre Manager, Teachers Mutual Bank Limited
                </Copy>
                <Copy bgColor={Color.Pink}>
                  <i>“daisee has reduced the training process for new staff members from 3-5 days at a minimum to less than a day”</i>
                  <br></br>
                  <br></br>
                  Stefania Papagiannopoulou,
                  <br></br>
                  Contact Centre Team Leader, Teachers Mutual Bank Limited
                </Copy>
                <Copy bgColor={Color.Pink}>
                  <i>“daisee gives us a daily report of high risk calls for team leaders to assess right away. Before daisee, problems would often only get picked up in the complaints system”</i>
                  <br></br>
                  <br></br>
                  Bette Safour,
                  <br></br>
                  National Contact Centre Manager, Teachers Mutual Bank Limited
                </Copy>

              </Article>
            </Story>

          </Inner>
        </Container>
      </CustomerStoriesWrapper>
    </section>
  )
}

const StyledTextLoop = styled(TextLoop)`
  color: ${({ theme }) => theme.colors.purple};
`

const Img = styled.img`
  transition: all 0.5s ease;
`

interface WrapperProps {
  bgColor: Color
}

const FeatureImg = styled.div`
  width: 36%;
`

const ImgWrapper = styled.div`
  max-width: 48%;

  &:before {
    transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, right 0.5s ease;
  }

  &:hover {
    ${Img} {
      transform: scale(1.05);
    }
  }
`

const LargerImgWrapper = styled(ImgWrapper)`
  max-width: 80%;
`

const Inner = styled.div`
  display: grid;
  gap: 4rem;
  grid-auto-rows: max-content;
  margin: 8rem 0;
`

const CustomerStoriesWrapper = styled.article`
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
`

const Header = styled.h1`
  font-size: 4.2vw;
  line-height: 1.2;
  letter-spacing: -2px;
  font-weight: 500;
  padding: 1.5rem 1.5rem 5rem;
  position: relative;
  height: fit-content;

  @media all and (max-width: 75em) {
    font-size: 5rem;
  }

  @media all and (max-width: 48em) {
    font-size: 4rem;
  }

  @media all and (max-width: 31.25em) {
    font-size: 3rem;
  }

  @media all and (max-width: 22.8125em) {
    font-size: 2.5rem;
  }
`

const Story = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  

  @media all and (max-width: 48em) {
    margin-top: 0;
  }
`

const Article = styled.div`
  width: 48%;
  margin: 3rem 0 3rem 0;
  display: grid;
  gap: 4rem;
  grid-auto-rows: max-content;
`

const Copy = styled.p<WrapperProps>`
  color: ${({ theme }) => theme.colors.grey700};
  font-weight: 500;
  line-height: 1.5;
  font-size: 1.5rem;
  position: relative;
  padding-left: 1.5rem;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    background: ${({ bgColor }) => getColor(bgColor)};
    width: 4px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  @media all and (max-width: 22.8125em) {
    font-size: 1rem;
  }
`