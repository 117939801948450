import styled from 'styled-components';
import React from 'react';
import Footer from '../components/Footer/Footer';
import CustomerStories from '../components/CustomerStories';
import TrustedPartners from '../components/TrustedPartners';

import { PageProps } from 'gatsby';
import { Site } from '../components/Site';
import { SEO } from '../SEO';
import { Main } from '../components/Styled';

export default (props: PageProps) => {
  return (
    <Site seo={SEO.customers} {...props}>
      <Main>
        <Customers>
          <CustomerStories />
        </Customers>
        <TrustedPartners className='customer-stories'/>
      </Main>
      <Footer />
    </Site>
  );
};

const Customers = styled.div`
  padding-top: 9rem;
`;
